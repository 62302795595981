import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import '../css/components.css';

import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';
import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';

import Thumbnail from '../assets/boot/thumbnail.jpg';

function Boot() {

  var name = localStorage.getItem("Firstname");
  var visited = localStorage.getItem("Visited");

  localStorage.setItem('Desktop', "");

  const [showBoot, setShowBoot] = useState(false);
  const [showBootText, setShowBootText] = useState(true);
  const [showWakeUp, setShowWakeUp] = useState(false);
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const [showWelcomeText1, setShowWelcomeText1] = useState(false);
  const [showWelcomeText2, setShowWelcomeText2] = useState(false);
  const [showWelcomeText3, setShowWelcomeText3] = useState(false);
  const [showDesktop, setShowDesktop] = useState(false);
  const [showTextForm, setShowTextForm] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [firstName, setFirstName] = useState(name);
  const history = useHistory();

  useEffect(() => window.localStorage.setItem("Firstname", firstName), [firstName]);

  function power() {

    if (visited === null) {
      document.getElementsByName('power')[0].className = 'button selected';
      setTimeout(() => {
        setShowBoot(true)
      }, 300);
     /* setTimeout(() => {
      requestFullScreen.call(docEl);
      }, 500); */
    }
    else {
      document.getElementsByName('power')[0].className = 'button selected';
      setTimeout(() => {
        setShowBoot(true)
        setShowBootText(false)
        setShowWakeUp(true)
      }, 300);  /*
      setTimeout(() => {
        requestFullScreen.call(docEl);
        }, 500); */
    }
  }

  function bootDone() {
    document.getElementsByName('boottext')[0].className = 'boottext-wrapper fade-out';
    setFirstName("");
    setTimeout(() => {
      setShowBootText(false)
      setShowWelcomeText1(true)
    }, 1500);
  }

  function wakeDone() {
    document.getElementsByName('boottext')[0].className = 'boottext-wrapper fade-out';
    setTimeout(() => {
      setShowWakeUp(false)
      setShowWelcomeBack(true)
    }, 1500);
  }


  function popUp() {
    setShowPopUp(true);
  }

  function popOut() {
    setTimeout(() => {
      document.getElementsByName('popup')[0].className = 'popup scale-out-horizontal';
      setShowWelcomeText1(false);
      setShowWelcomeText2(true);
    }, 1000);
  }

  function showForm() {
    setShowTextForm(true);
  }

  function submit(event) {
    event.preventDefault();
    document.getElementsByName('form')[0].className = 'fade-out';
    setTimeout(() => {
      setShowWelcomeText2(false);
      setShowWelcomeText3(true);
    }, 500);
  }

  function desktopReveal() {
    setShowDesktop(true);
    setTimeout(() => {
      history.push("/desktop");
    }, 1500);
  }

  return (
    <div className="main-wrapper">
      {!showBoot &&
        <div className="power-button">
          <div className="buttons-wrapper">
            <div name="power" className="button" onClick={power}>
              <span className="material-icons" style={{ fontSize: '2em' }}>
                power_settings_new
              </span>
            </div>
          </div>
          <div className="preload">
          <img src={Thumbnail} alt="" />
        </div>
        </div>
      }

      {showBoot &&
        <div className="monitor turn-on">
          <div className="rotate"><RotateIco /></div>

          {showBootText &&
            <div name="boottext" className="boottext-wrapper">
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ element: '▋' }} onTypingDone={bootDone}>
                <Typist.Delay ms={5000} />Launching jbOS v01: |
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />100%
                <br />Memory: OK
                <br />Version: webpack 3.11.0
                <br />Hash: 33d8c38093d5e8261eac
                <br />Time: 808ms
                <br />
                <br />Execute: highly_employable.php
                <br />
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <br />
                <br />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />Packet deployed
              </Typist >
            </div>
          }

          {showWakeUp &&
            <div name="boottext" className="boottext-wrapper">
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ element: '▋' }} onTypingDone={wakeDone}>
                <Typist.Delay ms={5000} />Launching jbOS v01: |
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />100%
                <br />Memory: OK
                <br />Version: webpack 3.11.0
                <br />Hash: 33d8c38093d5e8261eac
                <br />Time: 808ms
                <br />
                <br />Waking Up
                <br />
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
                <Typist.Delay ms={50} />.
              </Typist >
            </div>
          }

          {showWelcomeBack &&
            <Typist className="welcome-typist" avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={desktopReveal}>
              {firstName}!<Typist.Delay ms={800} />
                &nbsp;So nice to see you again.<Typist.Backspace count={70} delay={2000} />
                Everything is right where you left it.<Typist.Backspace count={39} delay={2000} />
              <Typist.Delay ms={100} />
            </Typist>
          }


          {showWelcomeText1 &&
            <Typist className="welcome-typist" avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋' }} onTypingDone={popUp}>
              <span style={{ fontWeight: 'bold' }}>*yawn*</span><Typist.Backspace count={6} delay={1500} />
                Oh...<Typist.Delay ms={800} />
                &nbsp;hey!<Typist.Backspace count={10} delay={1500} />
                Apologies<Typist.Delay ms={800} />
                , I didn't see you there.<Typist.Backspace count={34} delay={2000} />
                Matter of fact<Typist.Delay ms={1000} />
                , I can't see much of anything.<Typist.Backspace count={45} delay={2000} />
                Let me turn on the lights.<Typist.Delay ms={1000} />
            </Typist>
          }

          {showPopUp &&
            <div name="popup" className="popup scale-in-hor-center">
              <div className="window-bar"></div>
              <div className="fade-in">Override // CAMERA
                <br />
                <br />
                <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={popOut}>|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />/
                  <Typist.Backspace count={1} delay={100} />―
                  <Typist.Backspace count={1} delay={100} />\
                  <Typist.Backspace count={1} delay={100} />|
                  <Typist.Backspace count={1} delay={100} />ACCESS GRANTED!
              </Typist>
              </div>
            </div>
          }

          {showWelcomeText2 &&
            <div name="form">
              <Typist className="welcome-typist" avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={showForm}>
                Much better.<Typist.Backspace count={12} delay={2000} />
                Okay<Typist.Delay ms={800} />
                , now that I can see you...<Typist.Backspace count={31} delay={1500} />
                I'd be remiss if I didn't ask you your name.<Typist.Backspace count={44} delay={2500} />
                What do you typically go by?<Typist.Delay ms={500} />
              </Typist>
              {showTextForm &&
                <div className="fade-in">
                  <form onSubmit={submit}>
                    <label id="firstname">&#x3e;&nbsp;</label>
                    <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" name="firstName" required autoFocus />
                  </form>
                  <div className="form-tip">
                    Hit enter when done.
                    <br />
                    <br />
                    (Psst... this is only for entertainment purposes.
                    <br />
                    Your name is not shared, tracked or accessible to Josh)
                    <br />
                    <br />
                    <a className="cookie-policy" href="https://www.cookiepolicygenerator.com/live.php?token=WA2y6itPzazShtGZ4xtWaVYJrdqzZPeS" target="_blank" rel="noreferrer">Cookie Policy</a>
                  </div>
                </div>
              }
            </div>
          }

          {showWelcomeText3 &&
            <div name="hide">
              <Typist className="welcome-typist" avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={desktopReveal}>
                Perfect<Typist.Delay ms={500} />
                , thanks {firstName}.<Typist.Delay ms={1500} />
                &nbsp;It's nice to officially meet you.<Typist.Backspace count={90} delay={2000} />
                So {firstName}<Typist.Delay ms={1500} />
                , I'm guessing you're here to learn about your next hire?<Typist.Backspace count={15} delay={2200} />
                <span style={{ fontWeight: 'bold' }}>*sorry*</span><Typist.Backspace count={7} delay={500} />
                Josh Brown?<Typist.Backspace count={100} delay={2500} />
                Great<Typist.Delay ms={1000} />
                , let's jump right in.<Typist.Backspace count={27} delay={2000} />
                <Typist.Delay ms={200} />
              </Typist>
            </div>
          }


          {showDesktop &&
            <div className="app-wrapper">
              <div className="apps about-ico fade-in-tl">
                <AboutIco />
                <div>ABOUT</div>
              </div>
              <div className="apps fade-in-top">
                <SkillIco />
                <div>SKILLS</div>
              </div>
              <div className="apps fade-in-tr">
                <SoftwareIco />
                <div>SOFTWARE</div>
              </div>
              <div className="apps fade-in-left">
                <DocumentsIco />
                <div>RESUM&Eacute;</div>
              </div>
              <div className="apps fade-in-fwd">
                <MailIco />
                <div>EMAIL</div>
              </div>
              <div className="apps fade-in-right">
                <LinkedInIco />
                <div>LINKEDIN</div>
              </div>
              <div className="apps fade-in-bl">
                <MapsIco />
                <div>FIND JOSH</div>
              </div>
              <div className="apps fade-in-bottom">
                <DrinkIco />
                <div>HAPPY HOUR</div>
              </div>
              <div className="apps fade-in-br">
                <PowerIco />
              </div>
            </div>
          }
        </div>
      }

    </div>
  );
}

export default Boot;



