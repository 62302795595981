import React from 'react';
import { useHistory } from 'react-router-dom';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';

/* Files */

import { ReactComponent as PreviewIco } from '../assets/software/preview.svg';
import { ReactComponent as Accertify } from '../assets/software/accertify.svg';
import { ReactComponent as Illustrator } from '../assets/software/illustrator.svg';
import { ReactComponent as Indesign } from '../assets/software/indesign.svg';
import { ReactComponent as Photoshop } from '../assets/software/photoshop.svg';
import { ReactComponent as Premier } from '../assets/software/premier.svg';
import { ReactComponent as Appsflyer } from '../assets/software/appsflyer.svg';
import { ReactComponent as Brightedge } from '../assets/software/brightedge.svg';
import { ReactComponent as Confluence } from '../assets/software/confluence.svg';
import { ReactComponent as Equifax } from '../assets/software/equifax.svg';
import { ReactComponent as Figma } from '../assets/software/figma.svg';
import { ReactComponent as Analytics } from '../assets/software/analytics.svg';
import { ReactComponent as Firebase } from '../assets/software/firebase.svg';
import { ReactComponent as Optimize } from '../assets/software/optimize.svg';
import { ReactComponent as Invision } from '../assets/software/invision.svg';
import { ReactComponent as Jira } from '../assets/software/jira.svg';
import { ReactComponent as Launchdarkly } from '../assets/software/launchdarkly.svg';
import { ReactComponent as Excel } from '../assets/software/excel.svg';
import { ReactComponent as Powerpoint } from '../assets/software/powerpoint.svg';
import { ReactComponent as Visual } from '../assets/software/visual.svg';
import { ReactComponent as Word } from '../assets/software/word.svg';
import { ReactComponent as Miro } from '../assets/software/miro.svg';
import { ReactComponent as N2 } from '../assets/software/n2.svg';
import { ReactComponent as Onfido } from '../assets/software/onfido.svg';
import { ReactComponent as Optimizely } from '../assets/software/optimizely.svg';
import { ReactComponent as Pardot } from '../assets/software/pardot.svg';
import { ReactComponent as Pendo } from '../assets/software/pendo.svg';
import { ReactComponent as Plaid } from '../assets/software/plaid.svg';
import { ReactComponent as Postman } from '../assets/software/postman.svg';
import { ReactComponent as Productplan } from '../assets/software/productplan.svg';
import { ReactComponent as Qualtrics } from '../assets/software/qualtrics.svg';
import { ReactComponent as Sfmc } from '../assets/software/sfmc.svg';
import { ReactComponent as Sas } from '../assets/software/sas.svg';
import { ReactComponent as Sitecore } from '../assets/software/sitecore.svg';
import { ReactComponent as Sproutsocial } from '../assets/software/sproutsocial.svg';
import { ReactComponent as Trulioo } from '../assets/software/trulioo.svg';
import { ReactComponent as Typeform } from '../assets/software/typeform.svg';

/* Logos */

import accertify from '../assets/software/accertify.png';
import illustrator from '../assets/software/illustrator.png';
import indesign from '../assets/software/indesign.png';
import photoshop from '../assets/software/photoshop.png';
import premier from '../assets/software/premier.png';
import appsflyer from '../assets/software/appsflyer.png';
import brightedge from '../assets/software/brightedge.png';
import confluence from '../assets/software/confluence.png';
import equifax from '../assets/software/equifax.png';
import figma from '../assets/software/figma.png';
import analytics from '../assets/software/analytics.png';
import firebase from '../assets/software/firebase.png';
import optimize from '../assets/software/optimize.png';
import invision from '../assets/software/invision.png';
import jira from '../assets/software/jira.png';
import launchdarkly from '../assets/software/launchdarkly.png';
import excel from '../assets/software/excel.png';
import powerpoint from '../assets/software/powerpoint.png';
import visual from '../assets/software/visual.png';
import word from '../assets/software/word.png';
import miro from '../assets/software/miro.png';
import n2 from '../assets/software/n2.png';
import onfido from '../assets/software/onfido.png';
import optimizely from '../assets/software/optimizely.png';
import pardot from '../assets/software/pardot.png';
import pendo from '../assets/software/pendo.png';
import plaid from '../assets/software/plaid.png';
import postman from '../assets/software/postman.png';
import productplan from '../assets/software/productplan.png';
import qualtrics from '../assets/software/qualtrics.png';
import sfmc from '../assets/software/sfmc.png';
import sas from '../assets/software/sas.png';
import sitecore from '../assets/software/sitecore.png';
import sproutsocial from '../assets/software/sproutsocial.png';
import trulioo from '../assets/software/trulioo.png';
import typeform from '../assets/software/typeform.png';


function Toolbox() {


  localStorage.setItem("Desktop", "Software");
  const history = useHistory();

  function Close() {
    document.getElementsByName('software')[0].className = 'window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }

  function show(value) {
    document.getElementsByName(`${value}`)[0].className = 'preview quick-fade-in';
    document.getElementsByName(`${value}`)[0].style.zIndex = '3';
  }

  function hide(value) {
    document.getElementsByName(`${value}`)[0].style.zIndex = '2';
    setTimeout(() => {
      document.getElementsByName(`${value}`)[0].className = 'preview fade-out';
    }, 200);
  }

  return (
    <div className="main-wrapper" >
      <div className="monitor">
        <div className="rotate"><RotateIco /></div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>

        <div className="window" name="software">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>
          <div className="software-content">
          <div class="arrow">
                <span></span>
                <span></span>
                <span></span>
  
         </div>

            <div className="software-icon-wrapper">

              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('accertify')} onMouseLeave={() => hide('accertify')}>
                  <Accertify />
      ACCERTIFY<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('illustrator')} onMouseLeave={() => hide('illustrator')}>
                  <Illustrator />
      ADOBE<br/>ILLUSTRATOR
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('indesign')} onMouseLeave={() => hide('indesign')}>
                  <Indesign />
      ADOBE<br/>INDESIGN
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('photoshop')} onMouseLeave={() => hide('photoshop')}>
                  <Photoshop />
      ADOBE<br/>PHOTOSHOP
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('premier')} onMouseLeave={() => hide('premier')}>
                  <Premier />
      ADOBE<br/>PREMIER
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('appsflyer')} onMouseLeave={() => hide('appsflyer')}>
                  <Appsflyer />
      APPSFLYER<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('brightedge')} onMouseLeave={() => hide('brightedge')}>
                  <Brightedge />
      BRIGHTEDGE<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('confluence')} onMouseLeave={() => hide('confluence')}>
                  <Confluence />
      CONFLUENCE<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('equifax')} onMouseLeave={() => hide('equifax')}>
                  <Equifax />
      EQUIFAX<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('figma')} onMouseLeave={() => hide('figma')}>
                  <Figma />
      FIGMA<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('analytics')} onMouseLeave={() => hide('analytics')}>
                  <Analytics />
      GOOGLE<br/>ANALYTICS
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('firebase')} onMouseLeave={() => hide('firebase')}>
                  <Firebase />
      GOOGLE<br/>FIREBASE
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('optimize')} onMouseLeave={() => hide('optimize')}>
                  <Optimize />
      GOOGLE<br/>OPTIMIZE
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('invision')} onMouseLeave={() => hide('invision')}>
                  <Invision />
      INVISION<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('jira')} onMouseLeave={() => hide('jira')}>
                  <Jira />
      JIRA<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('launchdarkly')} onMouseLeave={() => hide('launchdarkly')}>
                  <Launchdarkly />
      LAUNCHDARKLY<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('excel')} onMouseLeave={() => hide('excel')}>
                  <Excel />
      MICROSOFT<br/>EXCEL
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('powerpoint')} onMouseLeave={() => hide('powerpoint')}>
                  <Powerpoint />
      MICROSOFT<br/>POWERPOINT
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('visual')} onMouseLeave={() => hide('visual')}>
                  <Visual />
      MICROSOFT<br/>VISUAL STUDIO
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('word')} onMouseLeave={() => hide('word')}>
                  <Word />
      MICROSOFT<br/>WORD
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('miro')} onMouseLeave={() => hide('miro')}>
                  <Miro />
      MIRO<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('n2')} onMouseLeave={() => hide('n2')}>
                  <N2 />
      N2 CMS<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('onfido')} onMouseLeave={() => hide('onfido')}>
                  <Onfido />
      ONFIDO<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('optimizely')} onMouseLeave={() => hide('optimizely')}>
                  <Optimizely />
      OPTIMIZELY<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('pardot')} onMouseLeave={() => hide('pardot')}>
                  <Pardot />
      PARDOT<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('pendo')} onMouseLeave={() => hide('pendo')}>
                  <Pendo />
      PENDO<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('plaid')} onMouseLeave={() => hide('plaid')}>
                  <Plaid />
      PLAID<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('postman')} onMouseLeave={() => hide('postman')}>
                  <Postman />
      POSTMAN<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('productplan')} onMouseLeave={() => hide('productplan')}>
                  <Productplan />
      PRODUCTPLAN<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('qualtrics')} onMouseLeave={() => hide('qualtrics')}>
                  <Qualtrics />
      QUALTRICS<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('sfmc')} onMouseLeave={() => hide('sfmc')}>
                  <Sfmc />
      SALESFORCE<br/>MARKETING CLOUD
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('sas')} onMouseLeave={() => hide('sas')}>
                  <Sas />
      SAS<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('sitecore')} onMouseLeave={() => hide('sitecore')}>
                  <Sitecore />
      SITECORE<br/>&nbsp;
   </div>
               </div>
   <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('sproutsocial')} onMouseLeave={() => hide('sproutsocial')}>
                  <Sproutsocial />
      SPROUT<br/>SOCIAL
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('trulioo')} onMouseLeave={() => hide('trulioo')}>
                  <Trulioo />
      TRULIOO<br/>&nbsp;
   </div>
              </div>
              <div className="software-icon quick-fade-in">
                <div className="software-icon-selection" onMouseOver={() =>
                  show('typeform')} onMouseLeave={() => hide('typeform')}>
                  <Typeform />
      TYPEFORM<br/>&nbsp;
   </div>
              </div>



            </div>

            <div className="preview-bar" >
              <div className="preview-tip" name="preview-tip">
                <PreviewIco />
              </div>

              <div name="accertify" className="preview hidden">
   <div className="preview-icon"><img alt="Accertify" src={accertify} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Accertify</span><br/><br/>Fraud prevention, chargeback management, digital identity & customer authentication. Used for scoring and risk rating customers at the acquisition stage.</p>
   </div>
</div>
<div name="illustrator" className="preview hidden">
   <div className="preview-icon"><img alt="Illustrator" src={illustrator} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Adobe Illustrator</span><br/><br/>Adobe Illustrator is a vector graphics editor and design program. Used to create custom logos and scalable vectors</p>
   </div>
</div>
<div name="indesign" className="preview hidden">
   <div className="preview-icon"><img alt="Indesign" src={indesign} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Adobe Indesign</span><br/><br/>Adobe InDesign is a desktop publishing and typesetting software application. Used for primarily physically printed media but also for rich PDFs and smart user forms.</p>
   </div>
</div>
<div name="photoshop" className="preview hidden">
   <div className="preview-icon"><img alt="Photoshop" src={photoshop} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Adobe Photoshop</span><br/><br/>Adobe Photoshop is a raster graphics editor. Used both professionally and personally for as long as I can remember.</p>
   </div>
</div>
<div name="premier" className="preview hidden">
   <div className="preview-icon"><img alt="Premier" src={premier} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Adobe Premier</span><br/><br/>Adobe Premiere Pro is a timeline-based video editing software. Used to create rich Marketing videos and internal content.</p>
   </div>
</div>
<div name="appsflyer" className="preview hidden">
   <div className="preview-icon"><img alt="Appsflyer" src={appsflyer} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">AppsFlyer</span><br/><br/>SaaS mobile marketing analytics and attribution platform. Used to leverage tracking and deep linking within app.</p>
   </div>
</div>
<div name="brightedge" className="preview hidden">
   <div className="preview-icon"><img alt="Brightedge" src={brightedge} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Brightedge</span><br/><br/>SEO content performance marketing platform. Used for real-time ranking and keyword reporting.</p>
   </div>
</div>
<div name="confluence" className="preview hidden">
   <div className="preview-icon"><img alt="Confluence" src={confluence} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Confluence</span><br/><br/>Confluence is a web-based corporate wiki. Used to created extensive and comprehensive guides for internal resources.</p>
   </div>
</div>
<div name="equifax" className="preview hidden">
   <div className="preview-icon"><img alt="Equifax" src={equifax} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Equifax</span><br/><br/>Consumer credit reporting agency. Used for soft credit footprints and electronic verification.</p>
   </div>
</div>
<div name="figma" className="preview hidden">
   <div className="preview-icon"><img alt="Figma" src={figma} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Figma</span><br/><br/>Web-based vector graphics editor and prototyping tool. Used for wireframing and proof of concepts</p>
   </div>
</div>
<div name="analytics" className="preview hidden">
   <div className="preview-icon"><img alt="Analytics" src={analytics} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Google Analytics</span><br/><br/>Probably don't need to explain this one - I'm a sucker for analytics and can subsequently geek out within GA for hours.</p>
   </div>
</div>
<div name="firebase" className="preview hidden">
   <div className="preview-icon"><img alt="Firebase" src={firebase} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Google Firebase</span><br/><br/>Mobile analytics. Used to track traffic, health and activity of app users.</p>
   </div>
</div>
<div name="optimize" className="preview hidden">
   <div className="preview-icon"><img alt="Optimize" src={optimize} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Google Optimize</span><br/><br/>Website optimization tool. Used to perform A/B testing across both public and secure environments.</p>
   </div>
</div>
<div name="invision" className="preview hidden">
   <div className="preview-icon"><img alt="Invision" src={invision} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Invision</span><br/><br/>Digital product design platform - used for wireframing and click through prototyping.</p>
   </div>
</div>
<div name="jira" className="preview hidden">
   <div className="preview-icon"><img alt="Jira" src={jira} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">JIRA</span><br/><br/>JIRA is used to plan, track, and manage agile and software development projects. I, like most PM's, live in JIRA and the Atlassian suite</p>
   </div>
</div>
<div name="launchdarkly" className="preview hidden">
   <div className="preview-icon"><img alt="Launchdarkly" src={launchdarkly} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">LaunchDarkly</span><br/><br/>Code deployment at the flick of a switch. Used to enable/ disable features, particularly during multivariant testing.</p>
   </div>
</div>
<div name="excel" className="preview hidden">
   <div className="preview-icon"><img alt="Excel" src={excel} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Microsoft Excel</span><br/><br/>Spreadsheet processor and canvas for artists who got tired of Microsoft paint (google it!)</p>
   </div>
</div>
<div name="powerpoint" className="preview hidden">
   <div className="preview-icon"><img alt="Powerpoint" src={powerpoint} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Microsoft Powerpoint</span><br/><br/>Presentation software released on April 20, 1987, initially for Macintosh System Operating system based computers only.</p>
   </div>
</div>
<div name="visual" className="preview hidden">
   <div className="preview-icon"><img alt="Visual" src={visual} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Microsoft Visual Studio</span><br/><br/>Integrated development environment. Used to code HTML, REACT and JS.</p>
   </div>
</div>
<div name="word" className="preview hidden">
   <div className="preview-icon"><img alt="Word" src={word} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Microsoft Word</span><br/><br/>Word processor first released on October 25, 1983. Used to write good.</p>
   </div>
</div>
<div name="miro" className="preview hidden">
   <div className="preview-icon"><img alt="Miro" src={miro} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Miro</span><br/><br/>Cross-device collaboration whiteboard for teams. Used to ensure delivery and "same-page" ideation.</p>
   </div>
</div>
<div name="n2" className="preview hidden">
   <div className="preview-icon"><img alt="N2" src={n2} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">N2 CMS</span><br/><br/>Open-source CMS system. WYSIWYG editor about as basic as they come!</p>
   </div>
</div>
<div name="onfido" className="preview hidden">
   <div className="preview-icon"><img alt="Onfido" src={onfido} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Onfido</span><br/><br/>AI that helps verify people's identities using photo-based ID collection and liveness detection. Used to drastically reduce identity theft and fraud.</p>
   </div>
</div>
<div name="optimizely" className="preview hidden">
   <div className="preview-icon"><img alt="Optimizely" src={optimizely} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Optimizely</span><br/><br/>Progressive delivery and experimentation software. A used to create multi-variant experiments and A/B test.</p>
   </div>
</div>
<div name="pardot" className="preview hidden">
   <div className="preview-icon"><img alt="Pardot" src={pardot} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Pardot</span><br/><br/>The B2B Marketing Automation arm of Salesforce. Used for lead capture, lead nurture, lead enrichment, drip campaigns, form creation and more.</p>
   </div>
</div>
<div name="pendo" className="preview hidden">
   <div className="preview-icon"><img alt="Pendo" src={pendo} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Pendo</span><br/><br/>The all-in-one product analytics platform for breakthrough digital experiences.</p>
   </div>
</div>
<div name="plaid" className="preview hidden">
   <div className="preview-icon"><img alt="Plaid" src={plaid} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Plaid</span><br/><br/>Financial data aggregator. Used to seamlessly and sercurely connect user Bank Accounts, gaining real-time insights.</p>
   </div>
</div>
<div name="postman" className="preview hidden">
   <div className="preview-icon"><img alt="Postman" src={postman} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Postman</span><br/><br/>API Development Platform. Used for endpoint testing.</p>
   </div>
</div>
<div name="productplan" className="preview hidden">
   <div className="preview-icon"><img alt="Productplan" src={productplan} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">ProductPlan</span><br/><br/>Roadmap Software for Product Managers. Used to track, score, scope and prioritize initiatives</p>
   </div>
</div>
<div name="qualtrics" className="preview hidden">
   <div className="preview-icon"><img alt="Qualtrics" src={qualtrics} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Qualtrics</span><br/><br/>Customer, product, brand & employee experience insights in one place. Used for NPS and customer sentiments.</p>
   </div>
</div>
<div name="sfmc" className="preview hidden">
   <div className="preview-icon"><img alt="Sfmc" src={sfmc} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Salesforce Marketing Cloud</span><br/><br/>Digital marketing automation and analytics software and services. Used for email generation, delivery, tracking, personalization and SMS.</p>
   </div>
</div>
<div name="sas" className="preview hidden">
   <div className="preview-icon"><img alt="Sas" src={sas} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">SAS</span><br/><br/>Business Intelligence & Analytics Software. Used to create integrated dashboards, reporting and analytics.</p>
   </div>
</div>
<div name="sitecore" className="preview hidden">
   <div className="preview-icon"><img alt="Sitecore" src={sitecore} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Sitecore</span><br/><br/>Customer experience and web content management. Lived inside this CMS for the better part of 3 years.</p>
   </div>
</div>
<div name="sproutsocial" className="preview hidden">
   <div className="preview-icon"><img alt="Sprout Social" src={sproutsocial} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Sprout Social</span><br/><br/>All-In-One social media management platform. Control, post, comment, reply all from this one aggregator.</p>
   </div>
</div>
<div name="trulioo" className="preview hidden">
   <div className="preview-icon"><img alt="Trulioo" src={trulioo} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Trulioo</span><br/><br/>Canadian-based electronic identity and address verification of both individuals and businesses. Used in the onboarding and activation of user accounts.</p>
   </div>
</div>
<div name="typeform" className="preview hidden">
   <div className="preview-icon"><img alt="Typeform" src={typeform} /></div>
   <div className="preview-spacer"></div>
   <div className="preview-text">
      <p><span className="preview-title">Typeform</span><br/><br/>SaaS that specializes in online form building and online surveys. Used for customer feedback, sentiment and lead generation.</p>
   </div>
</div>

            </div>

          </div>
        </div>

      </div>
    </div>


  );
}

export default Toolbox;



