import React from 'react';
import { useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';
import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';

function About() {

  localStorage.setItem("Desktop", "About");

  const firstName = localStorage.getItem('Firstname');
  const history = useHistory();

  function Close() {
    document.getElementsByName('about')[0].className = 'window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }

  return (
    <div name="main" className="main-wrapper">
      <div name="monitor" className="monitor">
        <div className="rotate"><RotateIco /></div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>


        <div className="window" name="about">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>
          <div className="about-photo"></div>
          <div className="about-content fade-in">
            <div className="about-text">
              Thanks for stopping by {firstName}!
              <br /><br />I appreciate your inbox is likely flooding in a sea of templatized CV's, so I hope - even just for a moment - my website provides some reprieve from the  'Calibri' claustrophobia...
              <br /><br />So firstly, a little about me. Born and raised on the sunny east coast of Australia, I moved to the Bay area over a decade ago, to further my career, my expertise, and my network. I've been fortunate to work across various verticals in my career, honing my skills across Product Management, Acquistion Marketing, Partner Management and Customer Service and throughout this journey, I've had the opportunity to work with some phenomenal individuals, across some amazing projects.
              <br /><br />I've always viewed myself as a bit of a swiss army knife, craving to widen my trench of knowledge at the same rate as deepening it, but the one thing I gravitate towards, almost habitually: customer experience betterments. Naturally, this guided me to Product Management. Now at the risk of sounding hyperbolic, I don't want to say I've found my calling, but I do genuinely derive satisfaction from the data-driven creativity and problem-solving purview of my day-to-day. It's immensely rewarding.
              <br /><br />Circling back to why you're here though, and how I can bring value to you and your organization - well, I'm not one for grandstanding (despite you being deep into a page solely about "me"), so let me highlight what matters most: 1) I have an aptitude for firmly focusing on solutions through the customer lens; 2) my attention to detail ensures a meticulous and holistic approach to any initiatives I tackle; 3) I pride myself on my interpersonal and motivational skills and; 4) my robust systems and technical knowledge position me as the perfect conduit between key stakeholders and engineering teams.
              <br /><br />I'm tenacious, inclusive, exude perpetual optimism, and hold my work to an infallible standard.
              <br /><br />So that's me in a nutshell. I'd love to chew the proverbial fat for a lot longer, but your time is precious, so for now, explore, enjoy and contact me if you'd like to discuss the prospect of working together. I look forward to hearing from you.
              <br /><br />
              <Typist cursor={{ show: false }}><span className="sign-off"><Typist.Delay ms={500} />Joshua Brown</span></Typist>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default About;



