import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import '../css/components.css';
import Desktopmmessage from '../components/desktopmessage';

import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';
import { ReactComponent as PreviewIco } from '../assets/software/preview.svg';
import { ReactComponent as ShakerIco } from '../assets/happy-hour/shaker.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as ResumeIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';

import aboutBW from '../assets/about/aboutBW.jpg';

function Desktop() {

  localStorage.setItem("Visited", true);
  var visited = localStorage.getItem('Desktop');
  const [showAboutWindow, setShowAboutWindow] = useState(false);
  const [showSkillsWindow, setShowSkillsWindow] = useState(false);
  const [showSoftwareWindow, setShowSoftwareWindow] = useState(false);
  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const [showLinkedInPopUp, setShowLinkedInPopUp] = useState(false);
  const [showResumeWindow, setShowResumeWindow] = useState(false);
  const [showFindJoshWindow, setShowFindJoshWindow] = useState(false);
  const [showHappyHourWindow, setShowHappyHourWindow] = useState(false);
  const [showPowerPopUp, setShowPowerPopUp] = useState(false);
  const history = useHistory();

  var doc = window.document;
  var docEl = doc.documentElement;
  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;

  function about() {
    setShowAboutWindow(true)
    setTimeout(() => {
      history.push("/about");
    }, 500);
  }

  function skills() {
    setShowSkillsWindow(true)
    setTimeout(() => {
      history.push("/skills");
    }, 500);
  }

  function software() {
    setShowSoftwareWindow(true)
    setTimeout(() => {
      history.push("/software");
    }, 500);
  }

  function resumeWindow() {
    setShowResumeWindow(true)
    setTimeout(() => {
      history.push("/resume");
    }, 500);
  }

  function findjosh() {
    setShowFindJoshWindow(true)
    setTimeout(() => {
      history.push("/find-josh");
    }, 500);
  }

  function happyhour() {
    setShowHappyHourWindow(true)
    setTimeout(() => {
      history.push("/happy-hour");
    }, 500);
  }

  function powerPopUp() {
    setShowPowerPopUp(true);
  }

  function PowerPopUpClose() {
    document.getElementsByName('PowerPopUp')[0].className = 'popup scale-out-horizontal';
    setTimeout(() => {
      setShowPowerPopUp(false);
    }, 500);
  }

  function emailPopUp() {
    setShowEmailPopUp(true);
  }

  function emailPopUpClose() {
    setTimeout(() => {
    window.location = "mailto:hirejoshbrown@outlook.com?subject=Let's connect!"
    }, 300);
    setTimeout(() => {
      document.getElementsByName('EmailPopUp')[0].className = 'popup scale-out-horizontal';
    }, 500);
    setTimeout(() => {
      setShowEmailPopUp(false);
    }, 1000);
  }

  function linkedinPopUp() {
    setShowLinkedInPopUp(true);
  }

  function linkedinPopUpClose() {
    var deviceAgent = navigator.userAgent.toLowerCase();
    var agentID = deviceAgent.match(/(iPad|iPhone|iPod)/i);
    if (agentID) {
    setTimeout(() => {
        window.location = "https://www.linkedin.com/in/joshua-e-brown/";
    }, 300);
      setTimeout(() => {
      document.getElementsByName('LinkedinPopUp')[0].className = 'popup scale-out-horizontal';
    }, 500);
      setTimeout(() => {
        setShowLinkedInPopUp(false);
        requestFullScreen.call(docEl);
      }, 1000);
    }
    else {
      setTimeout(() => {
        window.open("https://www.linkedin.com/in/joshua-e-brown/");
      }, 300);
      setTimeout(() => {
      document.getElementsByName('LinkedinPopUp')[0].className = 'popup scale-out-horizontal';
      }, 500);
      setTimeout(() => {
        setShowLinkedInPopUp(false);
      }, 1000);
    }
  }

  function turnOff() {
    document.getElementsByName('main')[0].style.backgroundColor = 'black';
    document.getElementsByName('monitor')[0].className = 'monitor turn-off';
    setTimeout(() => {
      history.push("/power-off");
    }, 500);
  }

  if (visited === "Power Off") {
    setTimeout(() => {
      document.getElementsByName('monitor')[0].className = 'monitor turn-on';
    }, .1);
  }

  
  
  return (
    <div name="main" className="main-wrapper">
      <div name="monitor" className="monitor">

        <div className="rotate"><RotateIco /></div>

        <div className="desktop-typist">
          <Desktopmmessage />
        </div>

        <div className="app-wrapper">
          <div className="apps" onClick={about}>
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps" onClick={skills}>
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps about-ico" onClick={software}>
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps" onClick={resumeWindow}>
            <ResumeIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps" onClick={emailPopUp}>
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps" onClick={linkedinPopUp}>
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps" onClick={findjosh}>
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps" onClick={happyhour}>
            <DrinkIco />
            <div>HAPPY HOUR</div >
          </div>
          <div className="apps" onClick={powerPopUp}>
            <PowerIco />
          </div>
        </div>


        {showAboutWindow &&
          <div className="window scale-in-hor-center">
            <div className="window-bar"></div>
            <div className="about-photo"></div>
          </div>
        }

        {showSkillsWindow &&
          <div className="window scale-in-hor-center">
            <div className="window-bar"></div>
            <div className="skills-wrapper fade-in">

              <table className="skills-header">
                <tbody>
                  <tr>
                    <td className="grow-column" style={{ width: '50%' }}>
                      jbOS:\SKILLS
                  </td>
                    <td style={{ textAlign: 'right' }}>
                      &#060;DIR&#062;
                  </td>
                  </tr>
                </tbody>
              </table>

              <div style={{ padding: '15px' }}></div>

              <table className="skills-header">
                <tbody>
                  <tr>
                    <td className="grow-column" style={{ width: '33.3%' }}>
                      SKILLS<br />
                    -----------
                  </td>
                    <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>
                      CREATED<br />
                    -----------
                  </td>
                    <td style={{ width: '33.3%', textAlign: 'right' }}>
                      SIZE<br />
                    -----------
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }


        {showSoftwareWindow &&
          <div className="window scale-in-hor-center">
            <div className="window-bar"></div>
            <div className="software-content">
              <div className="software-icon-wrapper"></div>
              <div className="preview-bar fade-in"><div className="preview-tip">
                <PreviewIco />
              </div></div>
            </div>
          </div>
        }

        {showResumeWindow &&
             <div className="window scale-in-hor-center">
             <div className="window-bar"></div>
             <div className="resume-content fade-in">
             Launching // RESUM&Eacute;<br /><br /><br />
             </div>
           </div>
        }

        {showEmailPopUp &&
          <div className="popup scale-in-hor-center" name="EmailPopUp">
            <div className="window-bar"></div>
            <div className="fade-in">
              Launching // EMAIL<br /><br />
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={emailPopUpClose}>&nbsp;
              <Typist.Backspace count={1} delay={500} />
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />OK!
              </Typist>
            </div>
            </div>
        }

        {showLinkedInPopUp &&
          <div className="popup scale-in-hor-center" name="LinkedinPopUp" >
            <div className="window-bar"></div>
            <div className="fade-in">
            Launching // LINKEDIN<br /><br />
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={linkedinPopUpClose}>&nbsp;
              <Typist.Backspace count={1} delay={500} />
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />/
              <Typist.Backspace count={1} delay={100} />―
              <Typist.Backspace count={1} delay={100} />\
              <Typist.Backspace count={1} delay={100} />|
              <Typist.Backspace count={1} delay={100} />OK!
              </Typist>
            </div>
            </div>
        }

        {showFindJoshWindow &&
          <div className="window scale-in-hor-center">
            <div className="window-bar"></div>
            <div className="maps-content fade-in">
            Locating // UNICORN_EMPLOYEE<br /><br /><br />
            </div>
          </div>
        }

        {showHappyHourWindow &&
          <div className="happy-hour-window scale-in-hor-center">
            <div className="window-bar"></div>
            <div className="mix fade-in">
            <ShakerIco/><br/>
            MIX ANOTHER
            </div>
            <div className="responsibly fade-in">
             PLEASE DRINK RESPONSIBLY
            </div>
          </div>
        }


        {showPowerPopUp &&
          <div className="popup-close" onClick={PowerPopUpClose}>
            <div name="PowerPopUp" className="popup scale-in-hor-center">
              <div className="window-bar"></div>
              <div className="fade-in">
                Are you sure you want to SHUTDOWN? <br /><br />
                <div className="shutdown">
                  <div className="popup-button" onClick={turnOff}>YES</div>
                  <span style={{ width: '33%' }}></span>
                  <div className="popup-button" onClick={PowerPopUpClose}>NO</div>
                </div>
                </div>
              </div>
            </div>
        }

        <div className="preload">
          <img src={aboutBW} alt="" />
        </div>

      </div>
    </div>


  );
}

export default Desktop;



