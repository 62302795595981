import React  from 'react';
import '../css/components.css';

function PowerOff() {

  localStorage.setItem("Desktop", "Power Off");

  return (
    <div name="main" className="main-wrapper" style={{backgroundColor: 'black'}}>
      <div className="footer fade-in" style={{ animationDelay: '2000ms'}}>
      <a href="javascript:history.back()">← GO BACK</a><br/>
        MADE WITH ♡ BY JOSH | © {new Date().getFullYear()} JOSHUA BROWN<br/>
        Icons from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
      </div>
    </div>
  );
}

export default PowerOff;



