import React from "react";
import { Route, HashRouter} from "react-router-dom";

//Components
import Boot from './components/boot'; 
import Desktop from './components/desktop';
import About from './components/about';
import Skills from './components/skills';
import Software from './components/software';
import Resume from './components/resume';
import Findjosh from './components/find-josh';
import HappyHour from './components/happy-hour';
import PowerOff from './components/power-off';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});


function App() {

  return (
    <div>
      <HashRouter>
          <Route exact path="/" component={Boot} />
          <Route path="/desktop" component={Desktop} />
          <Route path="/about" component={About} />
          <Route path="/skills" component={Skills} />
          <Route path="/software" component={Software} />
          <Route path="/resume" component={Resume} />
          <Route path="/find-josh" component={Findjosh} />
          <Route path="/happy-hour" component={HappyHour} />
          <Route path="/power-off" component={PowerOff} />
      </HashRouter>
    </div>
  );
}

export default App;

