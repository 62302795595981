import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';


function Resume() {

  localStorage.setItem("Desktop", "Resume");

  const history = useHistory();
  const [showResume, setShowResume] = useState(false);
  
  function showResumeFrame() {
    document.getElementsByName('resume')[0].className = 'resume-content fade-out';
    setTimeout(() => {
      setShowResume(!showResume);
    }, 500);
  }

  function Close() {
    document.getElementsByName('resumewindow')[0].className = 'window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }

  return (
    <div name="main" className="main-wrapper">
      <div name="monitor" className="monitor">

        <div className="rotate">
          <RotateIco />
          </div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps about-ico">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>

        <div className="window" name="resumewindow">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>

          {!showResume &&
            <div name="resume" className="resume-content">
              Launching // RESUM&Eacute;<br /><br />
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={showResumeFrame}>              |
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />OK!
                </Typist>
            </div>
          }
          {showResume &&
            <iframe title="Resume" className="resume" src={"./JoshBrownResume_Protected.pdf"}></iframe>
          }
        </div>
      </div>
    </div>



  );
}

export default Resume;



