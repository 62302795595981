import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typist from 'react-typist';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';


function Findjosh() {

  localStorage.setItem("Desktop", "Find Josh");

  const history = useHistory();
  const [showMaps, setShowMaps] = useState(false);

  let location = [

    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d485.91193244115595!2d-122.44866913215824!3d37.79729826113457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085872b69ee94b7%3A0x767aaa09da353e62!2sPresidio%20Social%20Club!5e1!3m2!1sen!2sus!4v1590976593431!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1824.9745921455624!2d-122.42408314173302!3d37.82670279493746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085811569574f23%3A0x189c28e85a71ae17!2sAlcatraz%20Island!5e1!3m2!1sen!2sus!4v1590992414615!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.2644724881407!2d-122.87515168435746!3d38.56397897962346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8086a53d8d255f8b%3A0xb4c9b75beb102b32!2sFlowers%20Vineyards%20%26%20Winery!5e1!3m2!1sen!2sus!4v1590992499147!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d913.2285734351841!2d-122.41651557075278!3d37.80930369873459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580e254330009%3A0xed9aa8b90062eab8!2zTXVzw6llIE3DqWNhbmlxdWU!5e1!3m2!1sen!2sus!4v1590992630623!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d256.25738358148374!2d-122.41978784862326!3d37.77737868653049!2m3!1f0!2f39.449184027766556!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x808580997aeae663%3A0xb2706dff83574f4a!2sSan%20Francisco%20City%20Hall!5e1!3m2!1sen!2sus!4v1590992833213!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d231.01245521457383!2d-122.44252032867624!3d37.80630023979591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085813e8d453f5b%3A0x36472452d277e990!2sPhilz%20Coffee!5e1!3m2!1sen!2sus!4v1663374222222!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d583.451690133942!2d-122.39590813104763!3d37.79423609714258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84660c500c409d2b!2sHyatt%20Regency%20San%20Francisco!5e1!3m2!1sen!2sus!4v1610143986019!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d457.77707029284494!2d-122.41811048287681!3d37.80749717836451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580e3d1434971%3A0x8ff48dc78f89c1b9!2sIn-N-Out%20Burger!5e1!3m2!1sen!2sus!4v1610144102131!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227.1922513285729!2d-122.45292087049599!3d37.78760487794895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808587317bf94eff%3A0xb16d662a9194be31!2sSpruce!5e1!3m2!1sen!2sus!4v1610144156669!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225.31797332153906!2d-122.39310980025846!3d37.793219378350564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580644adbec17%3A0x34b391d08d9fe6bc!2sBoulevard!5e1!3m2!1sen!2sus!4v1610144228384!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d217.732278711508!2d-122.42075961802212!3d37.75549387812993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f7e3efca04bd7%3A0xbb1368c1fc2a636c!2sLatin%20American%20Club!5e1!3m2!1sen!2sus!4v1610144304794!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d216.59537498445485!2d-122.43270913034999!3d37.797526493622904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5606db11fcdf833a!2sFlores!5e1!3m2!1sen!2sus!4v1610144383141!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58.21434663957916!2d-122.43068124555155!3d37.7976388349019!2m3!1f180!2f0!3f0!3m2!1i1024!2i768!4f35!3m3!1m2!1s0x808580db4ece278b%3A0xc5b460e75afbac76!2sBus%20Stop!5e1!3m2!1sen!2sus!4v1610144445101!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1673.3771996848932!2d-122.42859128216497!3d37.791650000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580c11ab4730b%3A0xc33e31debab892e0!2sLafayette%20Park!5e1!3m2!1sen!2sus!4v1610144513566!5m2!1sen!2sus',
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.9480858090205!2d-122.42910775596626!3d37.75958828976297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f7e1779aa70a7%3A0xa618e4eff1228d60!2sMission%20Dolores%20Park!5e1!3m2!1sen!2sus!4v1610144553092!5m2!1sen!2sus',

];

  let randomLocation = location[Math.floor(Math.random() * location.length)];
  
  function showMap() {
    document.getElementsByName('locator')[0].className = 'maps-content fade-out';
    setTimeout(() => {
      setShowMaps(!showMaps);
    }, 500);
  }

  function Close() {
    document.getElementsByName('maps')[0].className = 'window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }


  return (
    <div name="main" className="main-wrapper">
      <div name="monitor" className="monitor">

        <div className="rotate">
          <RotateIco />
          </div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps about-ico">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>

        <div className="window" name="maps">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>

          {!showMaps &&
            <div name="locator" className="maps-content">
              Locating // UNICORN_EMPLOYEE<br /><br />
              <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{ hideWhenDone: true, hideWhenDoneDelay: 0 }} onTypingDone={showMap}>              |
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />/
                <Typist.Backspace count={1} delay={100} />―
                <Typist.Backspace count={1} delay={100} />\
                <Typist.Backspace count={1} delay={100} />|
                <Typist.Backspace count={1} delay={100} />OK!
                </Typist>
            </div>
          }
          {showMaps &&
            <iframe title="Find Josh" className="map" src={randomLocation}></iframe>
          }
        </div>
      </div>
    </div>



  );
}

export default Findjosh;



