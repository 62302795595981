import React from 'react';
import Typist from 'react-typist';
import '../css/components.css';


function Desktopmessage() {

  const firstName = localStorage.getItem('Firstname');
  var visited = localStorage.getItem('Desktop');

  if (visited === "About") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
          <Typist.Delay ms={200} />
        So that's a little about Josh.<Typist.Backspace count={30} delay={2000} />
        Distilling him down to a few paragraphs isn't an easy feat.<Typist.Backspace count={59} delay={3000} />
        Then again<Typist.Delay ms={800} />
        , neither is having a conversation with a webpage.<Typist.Backspace count={60} delay={3000} />
        <Typist.Delay ms={500} />
        Okay<Typist.Delay ms={800} />
        , where to next {firstName}?<Typist.Backspace count={50} delay={3000} />
        </Typist>
      </div>
    )
  }

  if (visited === "Skills") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
        <Typist.Delay ms={200} />
        Impressive repertoire hey?<Typist.Backspace count={26} delay={2000}/> 
        I think you must unequivocally agree<Typist.Delay ms={1500}/>
        , in the truest sense of the phrase<Typist.Backspace count={71} delay={1500}/> 
        Josh certainly has "da skillz 2 pay da billz".<Typist.Backspace count={46} delay={3000}/> 
        <Typist.Delay ms={500} />
        Okay<Typist.Delay ms={800}/>
        , where to next {firstName}?<Typist.Backspace count={32} delay={3000}/> 
        </Typist>
      </div>
    )
  }

  if (visited === "Software") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
        <Typist.Delay ms={200} />
        Wow! <Typist.Delay ms={1000}/>
        Software<Typist.Delay ms={500}/>
        , Hardware<Typist.Delay ms={500}/>
        , Wetware... <Typist.Delay ms={500}/>
        Josh has used it all.<Typist.Backspace count={57} delay={2000}/> 
        If only there were ways these skills could be leveraged? <Typist.Backspace count={87} delay={3000}/> 
        Perhaps on a new team?<Typist.Backspace count={39} delay={2000}/> 
        Hmmmm... ;)<Typist.Backspace count={50} delay={3000}/> 
        <Typist.Delay ms={500} />
        Okay<Typist.Delay ms={800}/>
        , where to next {firstName}?<Typist.Backspace count={32} delay={3000}/> 
       </Typist>
      </div>
    )
  }

  if (visited === "Find Josh") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
        <Typist.Delay ms={200} />
        Ummmmmmm... <Typist.Delay ms={1000}/>
        so a quick note.<Typist.Backspace count={28} delay={1000}/> 
        You may want to keep that tracking on the hush-hush.<Typist.Backspace count={68} delay={2000}/> 
        Strictly speaking<Typist.Delay ms={800}/>
        , Josh doesn't technically know I'm watching him 24/7.<Typist.Backspace count={71} delay={3000}/> 
        <Typist.Delay ms={500} />
        Okay<Typist.Delay ms={800}/>
        , where to next {firstName}?<Typist.Backspace count={32} delay={3000}/>
     </Typist>
      </div>
    )
  }

  if (visited === "Happy Hour") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
        <Typist.Delay ms={200} />
        Ahhhhh libations!<Typist.Backspace count={19} delay={2000}/> 
        Well deserved if you made it all the way through this entire website.<Typist.Backspace count={69} delay={3000}/>         After all<Typist.Delay ms={1000}/>
        , it's 5 o'clock somewhere ;)<Typist.Backspace count={38} delay={3000}/> 
        <Typist.Delay ms={500} />
        Okay<Typist.Delay ms={800}/>
        , where to next {firstName}?<Typist.Backspace count={32} delay={3000}/>
     </Typist>
      </div>
    )
  }

  if (visited === "Power Off") {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
          <Typist.Delay ms={3000} />Woah<Typist.Delay ms={1000} />, that felt weird.<Typist.Backspace count={22} delay={2000}/>
          Let's maybe stay away from that power button.<Typist.Backspace count={45} delay={3000}/> 
I'm a little afraid of the dark :(<Typist.Backspace count={34} delay={2000}/> 
     </Typist>
      </div>
    )
  }

  else {
    return (
      <div>
        <Typist avgTypingDelay={5} stdTypingDelay={0} cursor={{ element: '▋', hideWhenDone: true, hideWhenDoneDelay: 0 }}>
          Select an item below to get started.<Typist.Backspace count={36} delay={3000} />
        </Typist>
      </div>
    )
  };

}

export default Desktopmessage;



