import React from 'react';
import { useHistory } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';




function Skills() {

  localStorage.setItem("Desktop", "Skills");

  const history = useHistory();

  function Close() {
    document.getElementsByName('skills')[0].className = 'window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }

  return (
    <div className="main-wrapper" >
      <div className="monitor">
        <div className="rotate"><RotateIco /></div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>

        <div className="window" name="skills">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>
          <div className="skills-wrapper">

            <table className="skills-header">
              <tbody>
                <tr>
                  <td className="grow-column" style={{ width: '50%' }}>
                    jbOS:\SKILLS
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    &#060;DIR&#062;
                  </td>
                </tr>
              </tbody>
            </table>

            <div style={{ padding: '15px' }}></div>

            <table className="skills-header">
              <tbody>
                <tr>
                  <td className="grow-column" style={{ width: '33.3%' }}>
                    SKILLS<br />
                    -----------
                  </td>
                  <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>
                    CREATED<br />
                    -----------
                  </td>
                  <td style={{ width: '33.3%', textAlign: 'right' }}>
                    SIZE<br />
                    -----------
                  </td>
                </tr>
              </tbody>
            </table>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Highly CX Focused
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  Product Management centers around building the right product for the right person. As such, I ensure the Customer Experience remains firmly in focus throughout any problem, solution, or initiative.
              </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Natural Born Engineer
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                I’m a textbook “tinkerer”; always keen to dissect and understand things in detail. Once a subject or project is in my reticle, I’ll dive deep down the rabbit hole - curiosity is an incredibly beneficial ally for a PM! 
              </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Habitual Owner
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  It's not enough to simply release a feature - one must own the e2e journey and lifecycle of the products delivered. I don't just release and move on - I watch my releases grow and mature!</div>
              </Collapsible>
            </div>



            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Accurate Scoping
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  Routinely working with various engineering leads has sharpened my scoping and sizing intuition. Prioritization can only be as effective as the accuracy of your estimations.
              </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Futurist/ Strategist
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  There's no point heading in a direction if it's wrong. Helping define the correct strategy has always been in my wheelhouse.</div>
              </Collapsible>
            </div>


      
            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Design Oriented
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  I've always had a passion for design and good looking products. A product must not only function well, but it must inspire confidence in every user. The aesthetic-usability effect shows that users perceive aesthetically pleasing design as design that’s more usable.              </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Stakeholder Relations
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                  I am articulate, professional, and a strong communicator.  Stakeholder relations (or any relations for that matter) come naturally to me.                 </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Captivating Storyteller
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                Developers must be taken on a journey. You won't get passion unless they understand the true value of the initiative they are working on.                </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Negotiator 
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                I've routinely dealt with external vendors to discuss large scale commercials.
                </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Prototyper
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                I geek out on wireframing! I believe most people are visual learners, and thus I always prefer to conceptualize solutions with as much reference material as possible. 
                </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Ticketmaster
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                Composing clear, succinct and nuanced JIRA stories is a real artform, something that is almost second nature to me. The devil's in the detail!        </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Vendor Management
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                Dealing with external Vendors comes naturally, and I treat each vendor as an augmentation of my team. 
                </div>
              </Collapsible>
            </div>


            <div className="quick-fade-in">
              <Collapsible triggerOpenedClassName="skills-expanded" transitionTime={70} trigger={
                <table className="skills-table">
                  <tbody>
                    <tr>
                      <td className="grow-column" style={{ width: '33.3%' }}>
                        \ Analytical
                  </td>
                      <td className="hide-column" style={{ width: '33.3%', textAlign: 'right' }}>{new Date(2023 - (Math.random() * (30)), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36), Math.random() * (36)).toLocaleString('en-GB')}</td>
                      <td style={{ width: '33.3%', textAlign: 'right' }}>{((Math.random() * (2000000))).toLocaleString(undefined, { maximumFractionDigits: 0 })} KB</td>
                    </tr>
                  </tbody>
                </table>
              }>
                <div className="skills-content">
                Data is the lifeblood of all products, whether current or prospective. While there are certainly no-brainer initiatives, I'm a big believer in ensuring data is the wind behind the sails.   
                              </div>
              </Collapsible>
            </div>


          
                  </div>
        </div>



      </div>
    </div>


  );
}

export default Skills;



