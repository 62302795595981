import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/components.css';

import { ReactComponent as Cross } from '../assets/universal/cross.svg';
import { ReactComponent as RotateIco } from '../assets/universal/rotate.svg';

import { ReactComponent as ShakerIco } from '../assets/happy-hour/shaker.svg';

import { ReactComponent as AboutIco } from '../assets/desktop/info.svg';
import { ReactComponent as SkillIco } from '../assets/desktop/skills.svg';
import { ReactComponent as SoftwareIco } from '../assets/desktop/software.svg';
import { ReactComponent as DocumentsIco } from '../assets/desktop/resume.svg';
import { ReactComponent as MailIco } from '../assets/desktop/mail.svg';
import { ReactComponent as MapsIco } from '../assets/desktop/maps.svg';
import { ReactComponent as DrinkIco } from '../assets/desktop/drink.svg';
import { ReactComponent as LinkedInIco } from '../assets/desktop/linkedin.svg';
import { ReactComponent as PowerIco } from '../assets/desktop/power.svg';

function HappyHour() {

  const firstName = localStorage.getItem('Firstname');
  localStorage.setItem("Desktop", "Happy Hour");

  const history = useHistory();
  const [selected, setSelected] = useState(0);

  function Close() {
    document.getElementsByName('happy-hour-window')[0].className = 'happy-hour-window scale-out-horizontal';
    setTimeout(() => {
      history.push("/desktop");
    }, 500);
  }

  function mix() {
    document.getElementsByName('happy-hour-window')[0].className = 'happy-hour-window shake-top';
    document.getElementsByName('happy-hour-content')[0].className = 'happy-hour-content fade-out';
    setTimeout(() => {
      document.getElementsByName('happy-hour-content')[0].className = 'happy-hour-content fade-in';
      document.getElementsByName('happy-hour-window')[0].className = 'happy-hour-window';
      setSelected(prev => {
        if (prev === cocktail.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 700);

  }

  var cocktail = [

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        {firstName}' s &nbsp;special
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Martini
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
        3 1⁄2 fl oz Bacardi Gold rum<br/>
        1 fl oz Grapefruit juice (pink)<br/>
        3⁄4 fl oz Luxardo Maraschino liqueur<br/>
        1 fl oz Lime juice (freshly squeezed)<br/>
        1⁄2 fl oz Giffard Sugar Cane Syrup
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
        Lime wedge
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
        SHAKE all ingredients with ice and fine strain into chilled glass.
        </div>
      </div>
    </div>,


    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
       Americano &nbsp;{firstName}
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
        Collins
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
        1 1⁄2 fl oz Italian red bitter liqueur<br/>
        1 1⁄2 fl oz Martini Rosso sweet vermouth<br/>
        2 1⁄2 fl oz Thomas Henry Soda Water
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
        Orange slice
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
        POUR all ingredients into glass and then fill with ice.
       </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        {firstName} &nbsp;lady
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
        Coupe
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
        1 1⁄2 fl oz Rutte Dry Gin<br/>
        3⁄4 fl oz Triple sec liqueur (40%)<br/>
        3⁄4 fl oz Lemon juice (freshly squeezed)<br/>
        1⁄3 fl oz Giffard Sugar Cane Syrup<br/>
        1⁄2 fl oz Pasteurised egg white (optional)
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
        Lemon zest twist
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
SHAKE all ingredients with ice and strain back into shaker. DRY SHAKE (without ice) and fine strain into
chilled glass.
       </div>
      </div>
    </div>,

<div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        Snow {firstName}
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Collins
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
        3 1⁄2 fl oz Lemonade<br/>
        3⁄4 fl oz Lime juice (freshly squeezed)<br/>
        2 fl oz Warninks Advocaat liqueur 
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
        Lime zest twist or lime slice
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
        POUR lemonade and lime juice into ice-filled glass and then the advocaat which will mostly sink though the
other ingredients. Lightly stir to integrate while retaining the lemonade's carbonation.

       </div>
      </div>
    </div>,


    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        {firstName}tini
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Martini
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          2 fl oz Ketel One Vodka<br />
          1⁄2 fl oz Black raspberry liqueur<br />
          1 1⁄2 fl oz Pineapple juice (fresh pressed)
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Pineapple wedge on rim
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          SHAKE all ingredients with ice and fine strain into chilled glass.
    </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          {firstName}'s &nbsp;margarita
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Old-fashioned glass
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          1 1⁄2 fl oz Patrón Reposado tequila<br />
        3⁄4 fl oz Triple sec liqueur (40%)<br />
        3⁄4 fl oz Lime juice (freshly squeezed)<br />
        1⁄6 fl oz Agave syrup<br />
        1⁄2 pinch Salt<br />
        4 drop Difford's Margarita Bitters (optional)
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Salt rim (optional) &#x26; lime wedge
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          SHAKE all ingredients with ice and strain into ice-filled glass.
     </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          {firstName} &nbsp;and &nbsp;famous
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Coupe
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          3⁄4 fl oz Del Maguey VIDA mezcal<br />
        3⁄4 fl oz Aperol<br />
        3⁄4 fl oz Yellow Chartreuse liqueur<br />
        3⁄4 fl oz Lime juice (freshly squeezed)
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Lime wedge
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          SHAKE all ingredients with ice and strain into chilled glass.
        </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          {firstName}'s &nbsp;francisco
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Collins
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          2 fl oz Ketel One Vodka<br />
        1⁄2 fl oz Triple sec liqueur (40%)<br />
        1⁄2 fl oz Giffard Banane du Brésil<br />
        1 1⁄2 fl oz Orange juice (freshly squeezed)<br />
        1 1⁄2 fl oz Pineapple juice (fresh pressed)<br />
        1⁄4 fl oz Giffard Grenadine Syrup
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Pineapple wedge
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          SHAKE all ingredients with ice and strain into ice-filled glass.
        </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          {firstName}'s &nbsp;smash
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Old-fashioned
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          12 fresh Basil leaves<br />
        2 fl oz Rutte Dry Gin<br />
        3⁄4 fl oz Lemon juice (freshly squeezed)<br />
        1⁄3 fl oz Giffard Sugar Cane Syrup
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          3 basil sprigs
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          MUDDLE basil in base of SHAKER. Add other ingredients, SHAKE with ice and fine strain into ice-filled
          glass.

        </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          Vieux &nbsp;Carré &nbsp;{firstName}
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Old-fashioned
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          1 fl oz Bourbon whiskey<br />
        1 fl oz Camus VSOP Cognac<br />
        1⁄4 fl oz Benedictine D.O.M.<br />
        1 fl oz Martini Rosso sweet vermouth<br />
        1 dash Angostura or other aromatic bitters<br />
        1 dash Peychaud's or other Creole-style bitters
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Lemon zest twist
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          STIR all ingredients with ice and strain into ice-filled glass.
        </div>
      </div>
    </div>,

    <div className="happy-hour-content" name="happy-hour-content">
      <div className="happy-hour-header">
        <div>
          Dirty &nbsp;{firstName} &nbsp;banana
        </div>
      </div>

      <div className="break">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
      </div>

      <div className="happy-hour-wrapper">
        <div className="table-left">
          Glass:
        </div>
        <div className="table-right">
          Collins
        </div>

        <div className="table-left">
          Ingredients:
        </div>
        <div className="table-right">
          1 1⁄2 fl oz Bacardi Gold rum<br />
        1 fl oz Coffee liqueur<br />
        1 fl oz Giffard Banane du Brésil<br />
        1 fl oz Single cream / half-and-half<br />
        1 fl oz Milk<br />
        1 fresh Banana (peeled)
        </div>

        <div className="table-left">
          Garnish:
        </div>
        <div className="table-right">
          Banana chunk on rim
        </div>

        <div className="table-left">
          Method:
        </div>
        <div className="table-right" style={{ textAlign: 'justify' }}>
          BLEND all ingredients with 6oz scoop crushed ice.
        </div>
      </div>
    </div>

  ];



  return (
    <div className="main-wrapper" >
      <div className="monitor">
        <div className="rotate"><RotateIco /></div>

        <div className="app-wrapper">
          <div className="apps">
            <AboutIco />
            <div>ABOUT</div>
          </div>
          <div className="apps">
            <SkillIco />
            <div>SKILLS</div>
          </div>
          <div className="apps">
            <SoftwareIco />
            <div>SOFTWARE</div>
          </div>
          <div className="apps">
            <DocumentsIco />
            <div>RESUM&Eacute;</div>
          </div>
          <div className="apps">
            <MailIco />
            <div>EMAIL</div>
          </div>
          <div className="apps">
            <LinkedInIco />
            <div>LINKEDIN</div>
          </div>
          <div className="apps">
            <MapsIco />
            <div>FIND JOSH</div>
          </div>
          <div className="apps">
            <DrinkIco />
            <div>HAPPY HOUR</div>
          </div>
          <div className="apps">
            <PowerIco />
          </div>
        </div>

        <div className="happy-hour-window" name="happy-hour-window">
          <Cross className="close fade-in-top" onClick={Close} />
          <div className="window-bar"></div>

          <div className="mix" onClick={mix} style={{ cursor: 'pointer' }}>
            <ShakerIco /><br />
            MIX ANOTHER
            </div>

          <div className="responsibly">
            PLEASE DRINK RESPONSIBLY
            </div>


          <div className="fade-in">
            {cocktail[selected]}
          </div>


        </div>



      </div>
    </div>


  );
}

export default HappyHour;



